// creta
import creta1 from "../images/slides/creta/1.jpg";
import creta2 from "../images/slides/creta/2.jpg";
import creta3 from "../images/slides/creta/3.jpg";

// elantra
// import elantra1 from "../images/slides/elantra/1.jpg";
// import elantra2 from "../images/slides/elantra/2.jpg";
// import elantra3 from "../images/slides/elantra/3.jpg";
// import elantra4 from "../images/slides/elantra/4.jpg";
// import elantra5 from "../images/slides/elantra/5.jpg";
// import elantra6 from "../images/slides/elantra/6.jpg";
// import elantra7 from "../images/slides/elantra/7.jpg";
// import elantra8 from "../images/slides/elantra/8.jpg";
// import elantra9 from "../images/slides/elantra/9.jpg";
// import elantra10 from "../images/slides/elantra/10.jpg";
// import elantra11 from "../images/slides/elantra/11.jpg";
// import elantra12 from "../images/slides/elantra/12.jpg";
// import elantra13 from "../images/slides/elantra/13.jpg";
// import elantra14 from "../images/slides/elantra/14.jpg";
// import elantra15 from "../images/slides/elantra/15.jpg";
// import elantra16 from "../images/slides/elantra/16.jpg";
// import elantra17 from "../images/slides/elantra/17.jpg";
// import elantra18 from "../images/slides/elantra/18.jpg";
// import elantra19 from "../images/slides/elantra/19.jpg";
// import elantra20 from "../images/slides/elantra/20.jpg";
// import elantra21 from "../images/slides/elantra/21.jpg";
// import elantra22 from "../images/slides/elantra/22.jpg";
// import elantra23 from "../images/slides/elantra/23.jpg";
// import elantra24 from "../images/slides/elantra/24.jpg";

// elantra NEW
import elantra_new1 from "../images/slides/elantra_new/1.jpg";
import elantra_new2 from "../images/slides/elantra_new/2.jpg";
import elantra_new3 from "../images/slides/elantra_new/3.jpg";
import elantra_new4 from "../images/slides/elantra_new/4.jpg";
import elantra_new5 from "../images/slides/elantra_new/5.jpg";
import elantra_new6 from "../images/slides/elantra_new/6.jpg";
import elantra_new7 from "../images/slides/elantra_new/7.jpg";
import elantra_new8 from "../images/slides/elantra_new/8.jpg";
import elantra_new9 from "../images/slides/elantra_new/9.jpg";
import elantra_new10 from "../images/slides/elantra_new/10.jpg";
import elantra_new11 from "../images/slides/elantra_new/11.jpg";
import elantra_new12 from "../images/slides/elantra_new/12.jpg";
import elantra_new13 from "../images/slides/elantra_new/13.jpg";

// palisade
import palisade1 from "../images/slides/palisade/1.jpg";
import palisade2 from "../images/slides/palisade/2.jpg";
import palisade3 from "../images/slides/palisade/3.jpg";
import palisade4 from "../images/slides/palisade/4.jpg";
import palisade5 from "../images/slides/palisade/5.jpg";
import palisade6 from "../images/slides/palisade/6.jpg";
import palisade7 from "../images/slides/palisade/7.jpg";
import palisade8 from "../images/slides/palisade/8.jpg";
import palisade9 from "../images/slides/palisade/9.jpg";
import palisade10 from "../images/slides/palisade/10.jpg";
import palisade11 from "../images/slides/palisade/11.jpg";
import palisade12 from "../images/slides/palisade/12.jpg";
import palisade13 from "../images/slides/palisade/13.jpg";
import palisade14 from "../images/slides/palisade/14.jpg";
import palisade15 from "../images/slides/palisade/15.jpg";
import palisade16 from "../images/slides/palisade/16.jpg";
import palisade17 from "../images/slides/palisade/17.jpg";
import palisade18 from "../images/slides/palisade/18.jpg";
import palisade19 from "../images/slides/palisade/19.jpg";
import palisade20 from "../images/slides/palisade/20.jpg";
import palisade21 from "../images/slides/palisade/21.jpg";
import palisade22 from "../images/slides/palisade/22.jpg";
import palisade23 from "../images/slides/palisade/23.jpg";
import palisade24 from "../images/slides/palisade/24.jpg";
import palisade25 from "../images/slides/palisade/25.jpg";
import palisade26 from "../images/slides/palisade/26.jpg";
import palisade27 from "../images/slides/palisade/27.jpg";
import palisade28 from "../images/slides/palisade/28.jpg";
import palisade29 from "../images/slides/palisade/29.jpg";
import palisade30 from "../images/slides/palisade/30.jpg";

// solaris
import solaris1 from "../images/slides/solaris/1.jpg";
import solaris2 from "../images/slides/solaris/2.jpg";
import solaris3 from "../images/slides/solaris/3.jpg";
import solaris4 from "../images/slides/solaris/4.jpg";
import solaris5 from "../images/slides/solaris/5.jpg";
import solaris6 from "../images/slides/solaris/6.jpg";
import solaris7 from "../images/slides/solaris/7.jpg";
import solaris8 from "../images/slides/solaris/8.jpg";
import solaris9 from "../images/slides/solaris/9.jpg";
import solaris10 from "../images/slides/solaris/10.jpg";
import solaris11 from "../images/slides/solaris/11.jpg";
import solaris12 from "../images/slides/solaris/12.jpg";
import solaris13 from "../images/slides/solaris/13.jpg";
import solaris14 from "../images/slides/solaris/14.jpg";
import solaris15 from "../images/slides/solaris/15.jpg";
import solaris16 from "../images/slides/solaris/16.jpg";
import solaris17 from "../images/slides/solaris/17.jpg";
import solaris18 from "../images/slides/solaris/18.jpg";
import solaris19 from "../images/slides/solaris/19.jpg";
import solaris20 from "../images/slides/solaris/20.jpg";
import solaris21 from "../images/slides/solaris/21.jpg";
import solaris22 from "../images/slides/solaris/22.jpg";
import solaris23 from "../images/slides/solaris/23.jpg";
import solaris24 from "../images/slides/solaris/24.jpg";
import solaris25 from "../images/slides/solaris/25.jpg";
import solaris26 from "../images/slides/solaris/26.jpg";
import solaris27 from "../images/slides/solaris/27.jpg";
import solaris28 from "../images/slides/solaris/28.jpg";
import solaris29 from "../images/slides/solaris/29.jpg";
import solaris30 from "../images/slides/solaris/30.jpg";

// sonata
import sonata1 from "../images/slides/sonata/1.jpg";
import sonata2 from "../images/slides/sonata/2.jpg";
import sonata3 from "../images/slides/sonata/3.jpg";
import sonata4 from "../images/slides/sonata/4.jpg";
import sonata5 from "../images/slides/sonata/5.jpg";
import sonata6 from "../images/slides/sonata/6.jpg";
import sonata7 from "../images/slides/sonata/7.jpg";
import sonata8 from "../images/slides/sonata/8.jpg";
import sonata9 from "../images/slides/sonata/9.jpg";
import sonata10 from "../images/slides/sonata/10.jpg";
import sonata11 from "../images/slides/sonata/11.jpg";
import sonata12 from "../images/slides/sonata/12.jpg";
import sonata13 from "../images/slides/sonata/13.jpg";
import sonata14 from "../images/slides/sonata/14.jpg";
import sonata15 from "../images/slides/sonata/15.jpg";
import sonata16 from "../images/slides/sonata/16.jpg";
import sonata17 from "../images/slides/sonata/17.jpg";
import sonata18 from "../images/slides/sonata/18.jpg";

// staria
import staria1 from "../images/slides/staria/1.jpg";
import staria2 from "../images/slides/staria/2.jpg";
import staria3 from "../images/slides/staria/3.jpg";
import staria4 from "../images/slides/staria/4.jpg";
import staria5 from "../images/slides/staria/5.jpg";
import staria6 from "../images/slides/staria/6.jpg";
import staria7 from "../images/slides/staria/7.jpg";
import staria8 from "../images/slides/staria/8.jpg";
import staria9 from "../images/slides/staria/9.jpg";
import staria10 from "../images/slides/staria/10.jpg";
import staria11 from "../images/slides/staria/11.jpg";
import staria12 from "../images/slides/staria/12.jpg";
import staria13 from "../images/slides/staria/13.jpg";
import staria14 from "../images/slides/staria/14.jpg";
import staria15 from "../images/slides/staria/15.jpg";
import staria16 from "../images/slides/staria/16.jpg";
import staria17 from "../images/slides/staria/17.jpg";
import staria18 from "../images/slides/staria/18.jpg";
import staria19 from "../images/slides/staria/19.jpg";
import staria20 from "../images/slides/staria/20.jpg";
import staria21 from "../images/slides/staria/21.jpg";
import staria22 from "../images/slides/staria/22.jpg";

// tucson
import tucson1 from "../images/slides/tucson/1.jpg";
import tucson2 from "../images/slides/tucson/2.jpg";
import tucson3 from "../images/slides/tucson/3.jpg";
import tucson4 from "../images/slides/tucson/4.jpg";
import tucson5 from "../images/slides/tucson/5.jpg";
import tucson6 from "../images/slides/tucson/6.jpg";
import tucson7 from "../images/slides/tucson/7.jpg";
import tucson8 from "../images/slides/tucson/8.jpg";
import tucson9 from "../images/slides/tucson/9.jpg";
import tucson10 from "../images/slides/tucson/10.jpg";
import tucson11 from "../images/slides/tucson/11.jpg";
import tucson12 from "../images/slides/tucson/12.jpg";
import tucson13 from "../images/slides/tucson/13.jpg";
import tucson14 from "../images/slides/tucson/14.jpg";
import tucson15 from "../images/slides/tucson/15.jpg";
import tucson16 from "../images/slides/tucson/16.jpg";
import tucson17 from "../images/slides/tucson/17.jpg";
import tucson18 from "../images/slides/tucson/18.jpg";
import tucson19 from "../images/slides/tucson/19.jpg";
import tucson20 from "../images/slides/tucson/20.jpg";
import tucson21 from "../images/slides/tucson/21.jpg";
import tucson22 from "../images/slides/tucson/22.jpg";
import tucson23 from "../images/slides/tucson/23.jpg";
import tucson24 from "../images/slides/tucson/24.jpg";
import tucson25 from "../images/slides/tucson/25.jpg";
import tucson26 from "../images/slides/tucson/26.jpg";
import tucson27 from "../images/slides/tucson/27.jpg";
import tucson28 from "../images/slides/tucson/28.jpg";
import tucson29 from "../images/slides/tucson/29.jpg";
import tucson30 from "../images/slides/tucson/30.jpg";
import tucson31 from "../images/slides/tucson/31.jpg";
import tucson32 from "../images/slides/tucson/32.jpg";
import tucson33 from "../images/slides/tucson/33.jpg";
import tucson34 from "../images/slides/tucson/34.jpg";
import tucson35 from "../images/slides/tucson/35.jpg";
import tucson36 from "../images/slides/tucson/36.jpg";
import tucson37 from "../images/slides/tucson/37.jpg";
import tucson38 from "../images/slides/tucson/38.jpg";
import tucson39 from "../images/slides/tucson/39.jpg";
import tucson40 from "../images/slides/tucson/40.jpg";
import tucson41 from "../images/slides/tucson/41.jpg";
import tucson42 from "../images/slides/tucson/42.jpg";
import tucson43 from "../images/slides/tucson/43.jpg";
import tucson44 from "../images/slides/tucson/44.jpg";
import tucson45 from "../images/slides/tucson/45.jpg";
import tucson46 from "../images/slides/tucson/46.jpg";
import tucson47 from "../images/slides/tucson/47.jpg";
import tucson48 from "../images/slides/tucson/48.jpg";
import tucson49 from "../images/slides/tucson/49.jpg";
import tucson50 from "../images/slides/tucson/50.jpg";
import tucson51 from "../images/slides/tucson/51.jpg";
import tucson52 from "../images/slides/tucson/52.jpg";
import tucson53 from "../images/slides/tucson/53.jpg";
import tucson54 from "../images/slides/tucson/54.jpg";
import tucson55 from "../images/slides/tucson/55.jpg";

// santafe
import santafe1 from "../images/slides/santafe/1.jpg";
import santafe2 from "../images/slides/santafe/2.jpg";
import santafe3 from "../images/slides/santafe/3.jpg";
import santafe4 from "../images/slides/santafe/4.jpg";
import santafe5 from "../images/slides/santafe/5.jpg";
import santafe6 from "../images/slides/santafe/6.jpg";
import santafe7 from "../images/slides/santafe/7.jpg";
import santafe8 from "../images/slides/santafe/8.jpg";
import santafe9 from "../images/slides/santafe/9.jpg";
import santafe10 from "../images/slides/santafe/10.jpg";
import santafe11 from "../images/slides/santafe/11.jpg";
import santafe12 from "../images/slides/santafe/12.jpg";
import santafe13 from "../images/slides/santafe/13.jpg";
import santafe14 from "../images/slides/santafe/14.jpg";
import santafe15 from "../images/slides/santafe/15.jpg";
import santafe16 from "../images/slides/santafe/16.jpg";
import santafe17 from "../images/slides/santafe/17.jpg";

// mufasa
import mufasa1 from "../images/slides/mufasa/1.jpg";
import mufasa2 from "../images/slides/mufasa/2.jpg";
import mufasa3 from "../images/slides/mufasa/3.jpg";
import mufasa4 from "../images/slides/mufasa/4.jpg";
import mufasa5 from "../images/slides/mufasa/5.jpg";
import mufasa6 from "../images/slides/mufasa/6.jpg";
import mufasa7 from "../images/slides/mufasa/7.jpg";
import mufasa8 from "../images/slides/mufasa/8.jpg";
import mufasa9 from "../images/slides/mufasa/9.jpg";
import mufasa10 from "../images/slides/mufasa/10.jpg";
import mufasa11 from "../images/slides/mufasa/11.jpg";
import mufasa12 from "../images/slides/mufasa/12.jpg";

export default {
  CARS: [
    {
      main_title: "HYUNDAI SOLARIS",
      folder_name: "SOLARIS",
      stock_name: "Solaris",
      price: "1 890 000",
      advantage: "525 000",
      trade_in: "130 000",
      installment: 0,
      foto_problem: false,
      availability:
        'Более <strong class="car__strong"> 50 авто</strong> <br>в наличии',
      colors: [
        "C3C3C3",
        "D3D8DF",
        "828385",
        "2170AA",
        "C6BEA9",
        "18161B",
        "A72225",
        "866641",
      ],
      activ_color: "2170AA",
      gallery: [
         solaris1,  solaris2,  solaris3,  solaris4,  solaris5, 
         solaris6,  solaris7,  solaris8,  solaris9, solaris10, 
        solaris11, solaris12, solaris13, solaris14, solaris15, 
        solaris16, solaris17, solaris18, solaris19, solaris20, 
        solaris21, solaris22, solaris23, solaris24, solaris25, 
        solaris26, solaris27, solaris28, solaris29, solaris30
      ],
    },
    {
      main_title: "HYUNDAI PALISADE",
      folder_name: "PALISADE",
      stock_name: "Palisade",
      price: "6 560 000",
      advantage: "1 340 000",
      trade_in: "200 000",
      installment: 0,
      foto_problem: false,
      dop: true,
      availability:
          'Более <strong class="car__strong"> 20 авто</strong> <br>в наличии',
      colors: [
        "494A4E",
        "1C2E3E",
        "5D656A",
        "797B7E",
        "F8F8F6",
        "380D15",
        "000000",
        "463E3D",
      ],
      activ_color: "380D15",
      gallery: [
        palisade1,  palisade2,  palisade3,  palisade4,  palisade5,  palisade6,  palisade7,  palisade8,  palisade9, palisade10,
        palisade11, palisade12, palisade13, palisade14, palisade15, palisade16, palisade17, palisade18, palisade19, palisade20,
        palisade21, palisade22, palisade23, palisade24, palisade25, palisade26, palisade27, palisade28, palisade29, palisade30
      ],
    },
    {
      main_title: "HYUNDAI SANTA FE",
      folder_name: "newSANTAFE",
      stock_name: "NEW Santa Fe",
      price: "4 855 000",
      advantage: "835 000",
      trade_in: "250 000",
      installment: 0,
      foto_problem: false,
      availability:
          'Более <strong class="car__strong"> 20 авто</strong> <br>в наличии',
      colors: [
        "5B5F5E",
        "495350",
        "F0EFEE",
        "AD2F1B",
        "000000",
        "B5B8BA",
        "463E3D",
        "C3C3C3",
        "405069",
      ],
      activ_color: "495350",
      gallery: [
        santafe1,  santafe2,  santafe3,  santafe4,  santafe5,  santafe6,  santafe7, santafe8, 
        santafe9, santafe10, santafe11, santafe12, santafe13, santafe14, santafe15, santafe16, 
        santafe17

      ],
    },
    {
      main_title: "HYUNDAI STARIA",
      folder_name: "STARIA",
      stock_name: "Staria",
      price: "6 670 000",
      advantage: "1 300 000",
      trade_in: "200 000",
      installment: 0,
      foto_problem: true,
      availability:
        'Более <strong class="car__strong"> 10 авто</strong> <br>в наличии',
      colors: ["F8F8F6"],
      activ_color: "F8F8F6",
      gallery: [
         staria1,  staria2,  staria3,  staria4,  staria5,  staria6,  staria7,  staria8,  staria9, staria10, 
        staria11, staria12, staria13, staria14, staria15, staria16, staria17, staria18, staria19, staria20, 
        staria21, staria22
      ],
    },
    {
      main_title: "HYUNDAI TUCSON",
      folder_name: "newTUCSON",
      stock_name: "Tucson",
      price: "3 110 000",
      advantage: "980 000",
      trade_in: "150 000",
      installment: 0,
      foto_problem: false,
      dop: true,
      availability:
          'Более <strong class="car__strong"> 55 авто</strong> <br>в наличии',
      colors: [
        "DCD5CA",
        "797B7E",
        "4E5457",
        "1C2E3E",
        "222222",
        "B1B5B7",
        "691620",
        "353635",
      ],
      activ_color: "691620",
      gallery: [
         tucson1,  tucson2,  tucson3,  tucson4,  tucson5,  tucson6,  tucson7,  tucson8,  tucson9, tucson10, 
        tucson11, tucson12, tucson13, tucson14, tucson15, tucson16, tucson17, tucson18, tucson19, tucson20, 
        tucson21, tucson22, tucson23, tucson24, tucson25, tucson26, tucson27, tucson28, tucson29, tucson30, 
        tucson31, tucson32, tucson33, tucson34, tucson35, tucson36, tucson37, tucson38, tucson39, tucson40, 
        tucson41, tucson42, tucson43, tucson44, tucson45, tucson46, tucson47, tucson48, tucson49, tucson50, 
        tucson51, tucson52, tucson53, tucson54, tucson55
      ],
    },
    {
      main_title: "HYUNDAI MUFASA",
      folder_name: "mufasa",
      stock_name: "Mufasa",
      price: "2 660 000",
      advantage: "1 130 000",
      trade_in: "300 000",
      installment: 0,
      foto_problem: false,
      availability: false,
      colors: [],
      activ_color: "",
      gallery: [
        mufasa1,  mufasa2, mufasa3, mufasa4, mufasa5, mufasa6, mufasa7, mufasa8, mufasa9, mufasa10, 
        mufasa11, mufasa12
      ],
    },
    {
      main_title: "HYUNDAI ELANTRA NEW",
      folder_name: "elantra_new",
      stock_name: "Elantra NEW",
      price: "2 070 000",
      advantage: "780 000",
      trade_in: "150 000",
      installment: 0,
      foto_problem: false,
      availability:
          'Осталось всего <br /><strong class="car__strong"> 5 авто</strong>в наличии',
      colors: [
        "E7E7E7",
        "777D81",
        "AF0101",
        "AD2F1B",
        "252726",
        "273452",
        "697073",
        "4E5457",
        "979797",
      ],
      activ_color: "273452",
      gallery: [
        elantra_new1,  elantra_new2,  elantra_new3, elantra_new4, elantra_new5, elantra_new6, elantra_new7, elantra_new8, elantra_new9, elantra_new10,
        elantra_new11, elantra_new12, elantra_new13
      ],
    },
    {
      main_title: "HYUNDAI SONATA",
      folder_name: "SONATA",
      stock_name: "Sonata",
      price: "3 100 000",
      advantage: "865 000",
      trade_in: "150 000",
      installment: 0,
      foto_problem: false,
      dop: true,
      availability:
          'Более <strong class="car__strong"> 10 авто</strong> <br>в наличии',
      colors: ["EFF0F2", "414548", "A3161F", "AFB1B0", "242C38", "000000"],
      activ_color: "EFF0F2",
      gallery: [
         sonata1,  sonata2,  sonata3,  sonata4,  sonata5,  sonata6,  sonata7,  sonata8,  sonata9, sonata10, 
        sonata11, sonata12, sonata13, sonata14, sonata15, sonata16, sonata17, sonata18
      ],
    },


    // {
    //   main_title: "HYUNDAI ELANTRA",
    //   folder_name: "newELANTRA",
    //   stock_name: "Elantra",
    //   price: "1 780 000",
    //   advantage: "470 000",
    //   trade_in: "100 000",
    //   installment: 0,
    //   foto_problem: false,
    //   availability:
    //       'Осталось всего <br /><strong class="car__strong"> 5 авто</strong> в наличии',
    //   colors: [
    //     "E7E7E7",
    //     "777D81",
    //     "AF0101",
    //     "AD2F1B",
    //     "252726",
    //     "273452",
    //     "697073",
    //     "4E5457",
    //     "979797",
    //   ],
    //   activ_color: "273452",
    //   gallery: [
    //      elantra1,  elantra2,  elantra3,  elantra4,  elantra5,  elantra6,  elantra7,  elantra8,  elantra9, elantra10,
    //     elantra11, elantra12, elantra13, elantra14, elantra15, elantra16, elantra17, elantra18, elantra19, elantra20,
    //     elantra21, elantra22, elantra23, elantra24
    //   ],
    // },


// {
    //   main_title: "HYUNDAI CRETA",
    //   folder_name: "newCRETA",
    //   stock_name: "Creta",
    //   price: 1859000,
    //   advantage: "470 000",
    //   trade_in: "100 000",
    //   installment: 0,
    //   foto_problem: false,
    //   availability:
    //     'Более <strong class="car__strong"> 100 авто</strong> <br>в наличии',
    //   colors: [
    //     "C3C3C3",
    //     "D3D8DF",
    //     "828385",
    //     "2170AA",
    //     "18161B",
    //     "866641",
    //     "A3161F",
    //   ],
    //   activ_color: "A72225",
    //   gallery: [creta1, creta2, creta3],
    // },
  ],
};
